import { offCanvasOpen, offCanvasClose, offcanvasDropdown } from "./modules/offcanvas";
import { nsCounterInput } from "./modules/counter-input";
import {animationObserver} from "./modules/animation-observer";

//import "./modules/slider";

offCanvasOpen();
offCanvasClose();
offcanvasDropdown();

if (document.querySelector(".product-quantity input.qty")) {
    nsCounterInput(".product-quantity input.qty", 0, 100);
    (function ($) {
        $(document.body).on("updated_cart_totals", function () {
            nsCounterInput(".product-quantity input.qty", 0, 100);
        });
    })(jQuery);
}
