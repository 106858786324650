function nsCounterInput(el, minValue, maxValue) {
    let counterEls = document.querySelectorAll(el);

    counterEls.forEach((counterEl) => {
        let previousSibling = counterEl.previousSibling;
        let nextSibling = counterEl.nextSibling;
        if (previousSibling) {
            counterEl.parentNode.removeChild(previousSibling);
        }
        if (nextSibling) {
            counterEl.parentNode.removeChild(nextSibling);
        }

        let plusBtnEl = `<button class="plus-btn">+</button>`;
        let minusBtnEl = `<button class="minus-btn">-</button>`;
        counterEl.insertAdjacentHTML("beforebegin", minusBtnEl);
        counterEl.insertAdjacentHTML("afterend", plusBtnEl);

        counterEl.dataset.min = minValue;
        counterEl.dataset.max = maxValue;

        counterEl.readOnly = true;
        const event = new Event("change", { bubbles: true });
        let minusBtn = counterEl.previousSibling;
        let plusBtn = counterEl.nextSibling;
        plusBtn.addEventListener("click", (el) => {
            el.preventDefault();
            let maxVal = counterEl.dataset.max;
            let currentVal = parseInt(counterEl.value);
            if (currentVal < maxVal) {
                counterEl.value = currentVal + 1;
                counterEl.dispatchEvent(event);
            }
        });
        minusBtn.addEventListener("click", (el) => {
            el.preventDefault();
            let minVal = counterEl.dataset.min;
            let currentVal = parseInt(counterEl.value);
            if (currentVal > minVal) {
                counterEl.value = currentVal - 1;
                counterEl.dispatchEvent(event);
            }
        });
    });
}

export { nsCounterInput };
