let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
}
let observer = new IntersectionObserver(entires => {
    entires.forEach(entry => {
        const intersecting = entry.isIntersecting
        if(intersecting) {
            entry.target.classList.add('animation-active');
        }   
    });
}, options);

function animationObserver(el) {
    let animationElements = document.querySelectorAll(el);
    animationElements.forEach(animationElement => {
        observer.observe(animationElement);
    });  
}

export {animationObserver};

